<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import DrawGoogleMap from "vue2-google-maps";

import Vue from "vue";
import { db, firebaseApp } from "./store/firebaseDb";

export default {
  components: {
    DrawGoogleMap,
  },
  data() {
    return {
      vueAppClasses: [],
    };
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
  },
  methods: {
    async getHouseInfoByUID(uid) {
      const customerName = $cookies.get("customer");
      // const hNumber = $cookies.get("home");
      console.log("customerName11=" + customerName);

      console.log("uid11=" + uid);

      const docRef = db
        .collection("customer/" + customerName + "/house/")
        .where("uid", "==", uid);

      const customerRef = await docRef.get();

      console.log(
        "getHouseInfoByUID customerRef11111 length=" + customerRef.docs.length
      );

      if (customerRef.docs.length == 0) {
        console.error("No such document!");
        return null;
      }

      return customerRef.docs[0].data();
    },

    getUserCloudManagement(cb) {
      const customerName = $cookies.get("customer");
      console.log("customerName=", customerName);

      db.collection("users")
        .where("customer_name", "==", customerName + "")
        .get()
        .then((custInfo) => {
          custInfo.forEach((doc) => {

            const user_cloud_config = doc.data();
            console.log("user_cloud_config1=", user_cloud_config);

            Vue.$cookies.set("user_cloud_config", user_cloud_config);
            this.user_cloud_config = $cookies.get("user_cloud_config");
            console.log("user_cloud_config2=", this.user_cloud_config);

            if (cb) cb();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async handleUid(uid) {
      console.log("[[handleUid]]=" + uid);
      firebaseApp
        .auth()
        .signOut()
        .then(async () => {
          firebaseApp
            .auth()
            .signInWithEmailAndPassword("web@koder3.com", "123456x")
            .then(
              async (user) => {
                console.log("user", user);

                const _uid = decodeURIComponent(uid);

                const decryptedText = this.CryptoJS.AES.decrypt(
                  _uid,
                  "k23qa"
                ).toString(this.CryptoJS.enc.Utf8);
                console.log("_uid", _uid);
                console.log("decryptedText", decryptedText);

                const custName = decryptedText.split("|")[0];
                console.log("custName22", custName);

                Vue.$cookies.set("customer", custName);
                var customer = $cookies.get("customer");
                console.log("customer222", customer);

                var houseData = await this.getHouseInfoByUID(_uid);
                Vue.$cookies.set("houseinfo", houseData);

                console.log("houseData", houseData);
                Vue.$cookies.set("home", houseData.house_no);

                // var customer = $cookies.get("customer");
                var home = $cookies.get("home");
                console.log("home", home);

                const thisGlo = this;

                if (home && customer) {
                  this.getUserCloudManagement(function () {
                    var t = new Date().getTime();
                    console.log(
                      "user_cloud_config3",
                      thisGlo.user_cloud_config
                    );

                    var goto = "/home-user-news?t=" + t;
                    console.log("goto=" + goto);

                    if (
                      thisGlo.user_cloud_config != null &&
                      thisGlo.user_cloud_config.enable_news
                    ) {
                      window.location.href = goto;
                    } else {
                      window.location.href = goto;
                    }
                  });

                  // this.$router.push("/home-user-news");
                } else {
                  this.$router.push("/pages/error-404");
                }

                Vue.$cookies.remove("userInfo");
                // this.getHouseInfo();
              },
              (err) => {
                alert(err.message);
                this.$router.push("/pages/login").catch((error) => {
                  if (error.name != "NavigationDuplicated") {
                    throw error;
                  }
                });
              }
            );
        });
    },
    toggleClassInBody(className) {
      if (className === "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className === "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },

  async created() {
    const url_string = window.location.href; // www.test.com?filename=test
    const url = new URL(url_string);
    const uid = url.searchParams.get("_uid");
    // alert(uid);

    const dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);

    if (uid !== null && uid.length > 2) {
      await this.handleUid(uid);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
